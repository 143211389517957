<template>
  <div class="has-text-centered">
    <img v-if="e3_image !== null" :src="e3_image" class="picture-cadre"/>

    <div class="has-text-right">
      <span
        v-if="e3_auteur !== null"
        style="margin-right: 9px;color: #aaa;font-family: cabin_sketch_regular;font-size: 19px;"
      >{{ $t(phrasePrefixe) }} {{ e3_auteur.toString().substr(0, 20) }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.picture-cadre {
  display: inline-block;

  // Pour le look
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  background-color: white;

  // Pour éviter que l'utilisateur sélectionne par erreur le canvas avec le doigt
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none; /* Disable selection/copy in UIWebView */
}
</style>

<script>
const phrases = [
  'sketchy.page_jeu.etat_3.messages_random.0',
  'sketchy.page_jeu.etat_3.messages_random.1',
  'sketchy.page_jeu.etat_3.messages_random.2',
  'sketchy.page_jeu.etat_3.messages_random.3',
  'sketchy.page_jeu.etat_3.messages_random.4',
  'sketchy.page_jeu.etat_3.messages_random.5',
  'sketchy.page_jeu.etat_3.messages_random.6',
  'sketchy.page_jeu.etat_3.messages_random.7',
  'sketchy.page_jeu.etat_3.messages_random.8',
];

export default {
  name: 'Etat3DevinerMot',

  data() {
    return {
      phrasePrefixe: phrases[this.randBetween(0, phrases.length - 1)],
    };
  },

  props: ['e3_auteur', 'e3_image'],
}
</script>
